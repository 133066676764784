import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import i18n from './i18n';
import { I18nextProvider } from "react-i18next";
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import editorReducer from "./redux/reducers/editorReducers";
import loginReducers from "./redux/reducers/loginReducers";
import adminReducers from "./redux/reducers/adminReducers";
import App from './App';
import getEditorTheme from "./mui-editor-theme";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import './stylesheets/index.css'
import './stylesheets/fonts.css'

const allReducers = combineReducers({ editorReducer: editorReducer, loginReducer: loginReducers, adminReducer:adminReducers });
const store = createStore(allReducers, undefined, applyMiddleware(ReduxThunk));

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<span>Loading...</span>}>
        <Provider store={store}>
            <ThemeProvider theme={createTheme(getEditorTheme())}>
              <App />
            </ThemeProvider>
        </Provider>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
