import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Splash from '../../assets/landing_background.png';
import incubatorLogo from '../../assets/Incubator_logo.svg';
import { setState, TokenGetter, getUserInfo } from "../../redux/actions/loginActions";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

//******************************************/

const Page = styled.div` 
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    `

const Workspace = styled.div`    
    top: 0;
    bottom: 0;
    left: 0;
    right: 420px;
    position: fixed;
    `

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 20%;
    bottom: 0;
    right: 0;
    width: 420px;
    min-width: 420px;
    `

const Logo = styled.div`
    padding-bottom: 100px;
    `

const LogoLine1 = styled.div`
    display: flex;
    align-items: center; 
    font-weight: 700;
    font-size: 46px;
    color: #323334;
    `

const Egg = styled.img`
    width: 43px;
    height: 54px;
    margin-right: 10px;
    align-items: center;
`

const LogoLine2 = styled.div`
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    font-weight: 400;
    font-size: 46px;
    margin-top: -6px;
    color: #323334;
    `

const SwapPanels = styled.div`
    position: relative;
`

//******************************************/

const useStyles = makeStyles({
    splash: {
        backgroundImage: `url(${Splash})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },

    textfield: {
        margin: '10px 0'
    },
    text: {
        margin: '10px 0',
        fontSize: '14px'
    },
    button: {
        cursor: 'pointer',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        padding: '8px 24px',
        height: '40px',
        border: '1.5px solid #002677',
        backgroundColor: '#002677',
        color: '#ffffff',
        borderRadius: '999px',
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: '700',
        fontFamily: 'Optum Sans,sans-serif,uhc_sans',

        "&:hover": {
            backgroundColor: '#0C558B',
            color: 'white',
        }
    },
    link: {
        color: 'var(--link-color)',
        fontWeight: 500,
        cursor: 'pointer'
    },
    check: {
        position: 'relative',
        top: 3,
        width: '16px',
        height: '16px',
        marginRight: '5px',
        color: '#C0C0C0'
    },
    saving: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '80px'
    }
});

const Authentication = ({ token, setToken, setResult }) => {
    const [loginState, setLoginState] = useState('DONE');

    const classes = useStyles();
    const { t } = useTranslation();

    const navigate = useNavigate();

    function handleLogin() {
        setLoginState('AUTHENTICATING');

        window.location.replace(`${process.env.REACT_APP_OIDC_AUTHORIZEURL}?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=openid+email+profile&redirect_uri=${window.location}`);

    }

    useEffect(()=>{
        if(token){
            getUserInfo(token, (userInfo)=>{
                setResult(userInfo);
                navigate('/editor');
            });
        }
    }, [token]);

    useEffect(()=>{
        let params = (new URL(document.location)).searchParams;
        const code = params.get("code");

        if(code){
            setLoginState('AUTHENTICATED');
            TokenGetter(code, (token)=>{
                if(token){
                    setToken(token);
                    getUserInfo(token, (userInfo)=>{
                        setResult(userInfo);
                        navigate('/editor');
                    });
                }
            });
        }
    });

    return (
        <Page>
            <Workspace className={classes.splash}>
            </Workspace>
            <Sidebar>
        {
            loginState ==='AUTHENTICATED'
            ?
                <CircularProgress />
            :
            <>
                <Logo>
                    <LogoLine1>
                        <Egg src={incubatorLogo} alt='incubator logo' />
                        {t("login.incubator")}
                    </LogoLine1>
                    <LogoLine2>{t("login.editor")}</LogoLine2>
                </Logo>

                <SwapPanels>
                    <div>
                        {
                            loginState === 'AUTHENTICATING'
                                ? <CircularProgress />
                                :
                                <button
                                    variant="outlined"
                                    className={classes.button}
                                    onClick={() => handleLogin()}
                                >
                                    Log in using One Healthcare ID
                            </button>
                        }
                    </div>
                </SwapPanels>
            </>
        }
            </Sidebar>
        </Page >
    )
}

const mapStateToProps = (state) => {
    state = state.loginReducer;

    return {
        state
    };
};

const mapDispatchToProps = {
    setState
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);


Authentication.propTypes = {
    setToken: PropTypes.func.isRequired
}