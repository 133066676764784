import React, { useEffect } from "react";
import Toolbar from './sidebar/widgets/Toolbar';
import Preview from './workspace/Preview';
import TabBar from './sidebar/TabBar';
import DesignMenu from './sidebar/design/Menu';
import FeatureMenu from './sidebar/features/Menu';
import ContentMenu from './sidebar/content/Menu';
import { connect } from "react-redux";
import { getProfileData } from '../../redux/actions/loginActions';
import { setState, loadConfigs, saveConfigs } from '../../redux/actions/editorActions';
import { loadAdminData, saveAdminData } from '../../redux/actions/adminActions';
import styled from 'styled-components';
import WelcomePopUp from "./sidebar/popUp/Welcome/WelcomePopUp";
import { CircularProgress } from "@material-ui/core";

//******************************************/

const Page = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 1300px;
    overflow-x: auto;
`

const Workspace = styled.div`
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 420px;
    background-color: #F8F8F8;
    min-width: 880px;
`

const Sidebar = styled.div`
    position: absolute;
    top: 60px;
    bottom: 0;
    right: 0px;
    width: 420px;
    min-width: 420px;    
`
const SwapPanels = styled.div`
    position: relative;
    top: 43%;
    left: 200px;
`

//******************************************/

const Editor = ({ editorState, setState, loadState, context, loadConfigs, saveConfigs, adminState, loadAdminData, saveAdminData, primaryColor, isDisabled, getProfileData, login, appId, appIdList, showSympleNoteHelpAtStartup, lastappId, appIdState, tabContext, tabIndex }) => {

    const handleTabChanged = (index) => {
        setState('context', tabContext[index]);
        setState('tabIndex', index);
    }

    // Get Configs and profile
    useEffect(() => {
        if(login && login.data && login.data.email && appId === ''){
            getProfileData(login && login.data && login.data.email);
            loadAdminData(login && login.data && login.data.email);
            setState('username', login.data.email);
        }
        if(appId !== lastappId){
            setState('appIdState', 'LOADING');
            loadConfigs(appId);

            setTimeout(()=>{
                setState('appIdState','DONE');
            }, [800]);
            setState('lastappId', appId);
        }
    }, [appId, login]);

    // Set Context
    useEffect(() => {
        let newTabContext = [...tabContext];
        newTabContext[tabIndex] = context || 'None';

        setState('tabContext', newTabContext);
    }, [context]);

    return (
        <>
            <Page>
                {
                    appIdState!== 'LOADING'
                    ?
                        <Toolbar 
                            save={() => saveConfigs(editorState, appId)} 
                        />
                    :
                        <></>
                }
                <Workspace>
                {
                    appIdState!=='LOADING'
                    ?
                        <Preview />
                    :
                    <></>
                }
                </Workspace>
                <Sidebar>
                {
                appIdState!=='LOADING'
                ?
                    <TabBar
                        tabs={[
                            'Design',
                            'Features',
                            'Content'
                        ]}
                        panes={[
                            <DesignMenu />,
                            <FeatureMenu />,
                            <ContentMenu />
                        ]}
                        defaultTab={tabIndex}
                        tabChanged={handleTabChanged}
                    />
                :
                    <SwapPanels>
                        <CircularProgress />
                    </SwapPanels>
                }
                </Sidebar>
            </Page>

            {
                showSympleNoteHelpAtStartup
                ?
                    <WelcomePopUp save={()=> saveAdminData(adminState, login.email, appIdList)} />
                :
                    <></>
            }

        </>
    )
}

const mapStateToProps = (state) => {
    const editorState = state.editorReducer;
    const loadState = state.editorReducer.loadState;
    const context = state.editorReducer.context;
    const appIdList = state.loginReducer.appIdList;
    const appId = state.loginReducer.appIdValue;
    const primaryColor = state.editorReducer.buttonColor;
    const isDisabled = state.editorReducer.stateChange;
    const adminState = state.adminReducer;
    const showSympleNoteHelpAtStartup = state.adminReducer.showSympleNoteHelpAtStartup;
    const lastappId = state.loginReducer.lastappId;
    const appIdState = state.editorReducer.appIdState;
    const tabIndex = state.editorReducer.tabIndex;
    const tabContext = state.editorReducer.tabContext;

    return {
        editorState,
        loadState,
        context,
        appIdList,
        appId,
        primaryColor,
        isDisabled,
        adminState,
        showSympleNoteHelpAtStartup,
        lastappId,
        appIdState,
        tabContext,
        tabIndex
    };
};

export default connect(mapStateToProps, { setState, loadConfigs, saveConfigs, getProfileData, loadAdminData, saveAdminData })(Editor);
