import {
    CONFIG_LOAD_STATE_SUCCESS,
    CONFIG_LOAD_STATE_FAIL,
    CONFIG_SAVE_STATE_SUCCESS,
    CONFIG_SAVE_STATE_FAIL,
    PROPERTY_CHANGED,

    DEFAULT_FAVICON_NAME,
    DEFAULT_FAVICON_DATA,
} from "../types";

const INITIAL_STATE = {
    urlFragment: '',
    loadState: '',
    saveState: '',
    context: 'none',
    header: 'both',
    faviconName: DEFAULT_FAVICON_NAME,
    faviconData: DEFAULT_FAVICON_DATA,
    logoName: null,
    logoData: null,
    textColor: '#5A5A5A',
    fontFamily: 'Source Sans Pro',
    buttonColor: '#0C5496',
    trimColor: '#0C5496',
    trimColorArray: ['#0C5496', '#13347A', '#335E6B', '#FF612B'],
    buttonColorArray : ['#0C5496', '#13347A', '#335E6B', '#002677'],
    showWelcomeImage: true,

    hasAlternatives: true,
    careAlternatives: [
        {
            "careType": "SelfCare",
            "isDisplayed": true
        },
        {
            "careType": "ContactMe",
            "isDisplayed": true
        },
        {
            "careType": "VirtualCare",
            "isDisplayed": true
        },
        {
            "careType": "InPerson",
            "isDisplayed": true
        },
        {
            "careType": "UrgentCare",
            "isDisplayed": true
        },
        {
            "careType": "EmergencyRoom",
            "isDisplayed": true
        },
        {
            "careType": "NineOneOne",
            "isDisplayed": true
        },
        {
            "careType": "None",
            "isDisplayed": true
        },
        {
            "careType": "ContactProvider",
            "isDisplayed": true
        },
        {
            "careType": "EVisit",
            "isDisplayed": true
        },
        {
            "careType": "Scheduling",
            "isDisplayed": true
        }
    ],

    hasPlans: true,
    patientPlans: [
        {
            "careType": "SelfCare",
            "isDisplayed": true,
            "displayText": "Loading"
        },
        {
            "careType": "ContactMe",
            "isDisplayed": true,
            "displayText": "Loading"
        },
        {
            "careType": "VirtualCare",
            "isDisplayed": true,
            "displayText": "Loading"
        },
        {
            "careType": "InPerson",
            "isDisplayed": true,
            "displayText": "Loading"
        },
        {
            "careType": "UrgentCare",
            "isDisplayed": true,
            "displayText": "Loading"
        },
        {
            "careType": "EmergencyRoom",
            "isDisplayed": true,
            "displayText": "Loading"
        },
        {
            "careType": "NineOneOne",
            "isDisplayed": true,
            "displayText": "Loading"
        },
        {
            "careType": "ContactProvider",
            "isDisplayed": true,
            "displayText": "Loading"
        },
        {
            "careType": "EVisit",
            "isDisplayed": true,
            "displayText": "Loading"
        },
        {
            "careType": "Scheduling",
            "isDisplayed": true,
            "displayText": "Loading"
        }
    ],

    hasNPS: true,
    hasFeedback: false,
    termsUrl: '',
    termsFileName: '',
    privacyUrl: '',
    privacyFileName : '',

    stateChange: false,

    submitButtonPopUp: true,

    username : '',

    cdoFullName : 'SympleNote',

    appIdState : 'LOADING',

    tabContext : ['None', 'None', 'None'],
    tabIndex : 0,

    showReportingTab : false
}

var loadState = { ...INITIAL_STATE };

const reducer = (state = INITIAL_STATE, action) => {

    const hasStateChange = (ConfigProperty, nextState) => {

        if (!['header', 'faviconData', 'faviconName', 'logoName', 'logoData', 'textColor', 'fontFamily', 'buttonColor', 'trimColor', 'showWelcomeImage', 'hasAlternatives', 'careAlternatives', 'hasPlans', 'patientPlans', 'hasNPS', 'hasFeedback', 'termsUrl', 'privacyUrl', 'privacyFileName', 'termsFileName'].includes(ConfigProperty))
            return state.stateChange;

        if (loadState.header !== nextState.header) return true;
        if (loadState.faviconName !== nextState.faviconName) return true;
        if (loadState.faviconData !== nextState.faviconData) return true;
        if (loadState.logoName !== nextState.logoName) return true;
        if (loadState.logoData !== nextState.logoData) return true;
        if (loadState.textColor !== nextState.textColor) return true;
        if (loadState.fontFamily !== nextState.fontFamily) return true;
        if (loadState.buttonColor !== nextState.buttonColor) return true;
        if (loadState.trimColor !== nextState.trimColor) return true;
        if (loadState.hasAlternatives !== nextState.hasAlternatives) return true;
        if (loadState.hasPlans !== nextState.hasPlans) return true;
        if (loadState.hasNPS !== nextState.hasNPS) return true;
        if (loadState.hasFeedback !== nextState.hasFeedback) return true;
        if (loadState.termsUrl !== nextState.termsUrl) return true;
        if (loadState.privacyUrl !== nextState.privacyUrl) return true;
        if (loadState.showWelcomeImage !== nextState.showWelcomeImage) return true;
        if (loadState.privacyFileName !== nextState.privacyFileName) return true;
        if (loadState.termsFileName !== nextState.termsFileName) return true;

        const hasCareAltChanged = loadState.careAlternatives.reduce((value, careAlt, index) => {
            return value && (careAlt.isDisplayed === nextState.careAlternatives[index].isDisplayed);
        }, true);

        if (!hasCareAltChanged) return true;

        const hasPatientPlanChanged = loadState.patientPlans.reduce((value, patplan, index) => {
            return value && (patplan.isDisplayed === nextState.patientPlans[index].isDisplayed);
        }, true);

        if (!hasPatientPlanChanged) return true;


        return false;
    }

    switch (action.type) {
        case PROPERTY_CHANGED:
            //            console.log('CHANGING ', action.payload.property, action.payload.value)
            const nextState = { ...state, [action.payload.property]: action.payload.value }

            return { ...state, [action.payload.property]: action.payload.value, ...{ stateChange: hasStateChange(action.payload.property, nextState) } }

        case CONFIG_LOAD_STATE_SUCCESS:
            console.log("Configs loaded:", action.payload)

            loadState = { ...state, ...action.payload, ...{ stateChange: false } }

            return loadState;

        case CONFIG_LOAD_STATE_FAIL:
            console.log("Configs failed to load", action.payload)
            return state;

        case CONFIG_SAVE_STATE_SUCCESS:
            console.log("Configs saved:", action.payload)

            loadState = { ...state, ...action.payload, ...{ stateChange: false } }

            return loadState;

        case CONFIG_SAVE_STATE_FAIL:
            console.log("Configs failed to save.", action.payload)
            return state

        default:
            return state;
    }
};

export default reducer;
