import axios from "axios";
import {
    PROPERTY_CHANGED,
    REGISTER_SAVE_STATE,
    LOGIN_STATE,
    USERS_LOAD_STATE,
    USER_DELETE_STATE,
    PROFILE_LOAD_STATE
} from "../types";

let azureAPI = axios.create({
    baseURL: "https://ac-admin-services-dev.optum.com/api"
});

export const setState = (property, value) => {
    return dispatch => {
        dispatch({
            type: PROPERTY_CHANGED,
            payload: { property, value }
        })
    };
};

export const TokenGetter = (code, callback) => {

        console.log('inside token getter call ', code);

        const url = 'https://incubator-customer-api-dev.optum.com/api/GetTokenAdminApp';

        const header = {
            headers: { "Content-Type": "application/json" }
        }

        axios
            .post(url, data(code), header)
            .then((response)=>{
                callback(response.data.access_token);
                console.log(response.data);
                return response.data.access_token;
            })
            .catch((error)=>{
                console.log(error);
                return error;
            })
}

const data = (auth_code) => {
    return {
        'code' : auth_code
    }
}

//get user Info
export const getUserInfo = async (accessToken, callback) => {
    if(!accessToken) return ;

    const url = 'https://incubator-customer-api-dev.optum.com/api/GetUserInfoAdminAppFunction';

    axios
        .get(`${url}?access_token=${accessToken}`)
        .then((response)=>{
            callback(response);
            console.log(response);
            return response;
        })
        .catch((error)=> {
            console.log(error);
            return error;
        });
}

export const getProfileData = (username) => {
    if(!username) return;

    if(!(typeof username === 'string' || username instanceof String)) return;

    return dispatch => {
        dispatch({ type: PROFILE_LOAD_STATE, payload: "LOADING" });

        axios
            .get(`https://incubator-customer-api-dev.optum.com/api/GetSympleNoteAdminUser?email=${username}`)
            .then(response => {
                dispatch({ type: PROFILE_LOAD_STATE, payload: { state: "SUCCESS", value: response.data } });
            })
            .catch(function (error) {
                dispatch({ type: PROFILE_LOAD_STATE, payload: { state: "ERROR", value: {} } });
            });
    };
}

export const saveRegistration = (username, email, password) => {
    return dispatch => {
        dispatch({ type: REGISTER_SAVE_STATE, payload: "LOADING" });

        Object.assign(azureAPI.defaults, {
            headers: { "Content-Type": "application/json" }
        });

        azureAPI.post(
            "register",
            {
                "username": username,
                "email": email.toLowerCase(),
                "password": password
            }
        )
            .then(response => {
                dispatch({ type: REGISTER_SAVE_STATE, payload: "SUCCESS" });
            })
            .catch(function (error) {
                dispatch({ type: REGISTER_SAVE_STATE, payload: "ERROR" });
            });

    };
}

export const login = (email, password) => {
    return dispatch => {
        dispatch({ type: LOGIN_STATE, payload: { state: "LOADING", value: "" } });

        Object.assign(azureAPI.defaults, {
            headers: { "Content-Type": "application/json" }
        });

        azureAPI.post(
            "login",
            {
                "email": email.toLowerCase(),
                "password": password
            }
        )
            .then(response => {
                dispatch({ type: LOGIN_STATE, payload: { state: "SUCCESS", value: response.data } });
            })
            .catch(function (error) {
                dispatch({ type: LOGIN_STATE, payload: { state: "SUCCESS", value: "" } });
            });

    };
}

export const getUsers = () => {
    return dispatch => {
        dispatch({ type: USERS_LOAD_STATE, payload: { state: "LOADING", value: [] } });

        Object.assign(azureAPI.defaults, {
            headers: { "Content-Type": "application/json" }
        });

        azureAPI.get(
            "getUsers?code=rgZTRf9tLELRruUhjGQcBXzj/nRdk6joTGFpCihPbc5axCSakKCyuw==", {}
        )
            .then(response => {
                dispatch({ type: USERS_LOAD_STATE, payload: { state: "SUCCESS", value: response.data } });
            })
            .catch(function (error) {
                dispatch({ type: USERS_LOAD_STATE, payload: { state: "ERROR", value: [] } });
            });

    };
}

export const deleteUser = (id) => {
    return dispatch => {
        dispatch({ type: USER_DELETE_STATE, payload: "LOADING" });

        Object.assign(azureAPI.defaults, {
            headers: { "Content-Type": "application/json" }
        });

        azureAPI.post(
            "deleteUser",
            {
                "id": id
            }
        )
            .then(response => {
                console.log("SUCCESS: response:");
                console.log(response.data);
                dispatch({ type: USER_DELETE_STATE, payload: "SUCCESS" });
            })
            .catch(function (error) {
                console.log("FAILED: login" + error);
                dispatch({ type: USER_DELETE_STATE, payload: "ERROR" });
            });

    };
}
