import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Authentication from './components/login/LoginPage';
import WelcomeScreen from './components/welcome/WelcomePage';
import ProductSelect from './components/product-select/ProductSelect';
import Dashboard from './components/reporting/Dashboard';
import Editor from './components/sn-editor/EditorPage';
import Support from "./components/support/Support";
import useToken from './useToken';
import { getUserInfo } from "./redux/actions/loginActions";


const App = () => {

    const {token, setToken} = useToken();
    const [result, setResult] = useState({});

    useEffect(()=>{
        if(token){
            getUserInfo(token, (userInfo)=>{
                setResult(userInfo);
                return <Editor login={result} />
            });
        }
    }, [token]);

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<Authentication token={token} setToken={setToken} setResult={setResult} /> } />
                <Route exact path="/welcome" element={<WelcomeScreen />} />
                <Route exact path="/product-select" element={<ProductSelect />} />
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/reporting" element={<Dashboard />} />
                <Route exact path="/editor" element={<Editor login={result} />} />
                <Route exact path="/support" element={ <Support login={result} /> } />
            </Routes>
        </BrowserRouter>
    )
}

export default App;